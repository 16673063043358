import { regexToPatternString } from '@/data/Regex'
import { RuleItem } from 'async-validator'
import { staticValues } from '@/static/StaticValues'
import ManageTableOptions from '@/models/forms/ManageTableOptions'

const inputText: ManageTableOptions = { type: 'input', inputType: 'text' }
export const invalidApplyTransferOptions: Dictionary<ManageTableOptions> = {
  id: { type: 'hide' },
  country: { type: 'hide' },
  corps_id: inputText,
  amount: inputText,
  base_currency: {
    type: 'select',
    option: 'transferableCountryCurrencies',
    header: {
      icon_class: 'el-icon-warning-outline',
      icon_handler_name: 'openInfoModal'
    }
  },
  comment: {
    type: 'input',
    inputType: 'textarea',
    maxlength: 20,
    showLimit: true
  }
}

export const applyTransferOptions: Dictionary<ManageTableOptions> = {
  id: { type: 'hide' },
  amount: inputText,
  base_currency: {
    type: 'select',
    option: 'transferableCountryCurrencies',
    header: {
      icon_class: 'el-icon-warning-outline',
      icon_handler_name: 'openInfoModal'
    }
  },
  invoice: {
    type: 'file'
  },
  comment: {
    type: 'input',
    inputType: 'textarea',
    width: 300,
    maxlength: 20,
    showLimit: true
  }
}

export const manualApplyTransferOptions: Dictionary<ManageTableOptions> = {
  amount: inputText,
  base_currency: {
    type: 'select',
    option: 'transferableCountryCurrencies',
    header: {
      icon_class: 'el-icon-warning-outline',
      icon_handler_name: 'openInfoModal'
    }
  },
  invoice: {
    type: 'file'
  },
  comment: {
    type: 'input',
    inputType: 'textarea',
    width: 300,
    maxlength: 20,
    showLimit: true
  }
}

const required: RuleItem = { required: true }
export const applyTransferRules: Dictionary<RuleItem> = {
  corps_id: {
    ...required,
    pattern: regexToPatternString('corps_id'),
    message: 'invalid format.'
  },
  amount: {
    ...required,
    pattern: regexToPatternString('amount'),
    validator: (rule, value) => !Number.isNaN(value) && value > 0,
    message: 'invalid format'
  },
  base_currency: {
    ...required,
    validator: (rule, value) => value && staticValues && staticValues.allTransferableCurrencies.includes(value),
    message: 'currency is invalid. please re-select'
  }
}


import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class BaseCurrencyInfo extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false
  }) isModal!: boolean
}
